import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { LeadsApi } from "@unity/components";
import Loading from "../../../common/Loading";
import AuthContext from "../../../services/AuthContext";

export default function TargetToLeadForm({
  open,
  handleCancel,
  handleSelectChange,
  handleChange,
  handleAllocateChange,
  handleSave,
  client,
  users,
  data,
  targetData,
}) {

  const context = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState(false);
  const [source, setSource] = useState(false);
  const [notes, setNotes] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleConvert = async () => {
    if (!selectedUser) return alert("Please select an allocation first");
    if (!targetData)
      return alert("Target not found. Please refresh and try again");
    if (!source) return alert("Please enter a lead source");

    try {
      setLoading(true);
      const res = await LeadsApi.createLeads({
        client_name: targetData.company_name,
        client_street: targetData.address_line_1,
        client_town: targetData.town,
        client_postcode: targetData.postcode,
        client_email: targetData.contact_email,
        client_tel: targetData.contact_phone,
        client_mobile: targetData.contact_mobile,
        client_sic_id: targetData.sic_code,
        client_sic_description: targetData.sic_description,
        client_no_of_employees: targetData.number_of_empployees,
        client_website: targetData.website,
        client_region: targetData.region,
        client_industry: targetData.industry,
        tenant_uuid: context.auth.tenant_uuid,
        created_by_uuid: context.auth.id,
        created_by_id: context.auth.contact,
        created_by_name: context.auth.name,
        legacy_target_id: targetData.id,
        public: false,
        is_lead: true,
        is_target: false,
        is_supplier: false,
        is_client: false,
        allocated_uuid: selectedUser,
        lead_contact_source: source,
        notes: notes,
      });
      if (res?.data == undefined || res?.data == null) throw "Call Failed";
    } catch (error) {
      alert(`Error updating target - ${error}`);
    }
    handleCancel();
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleCancel()}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <>
            <h4 style={{ paddingBottom: 5 }}>
              Converting {targetData.company_name} into a lead
            </h4>
            {users && users.length > 0 ? (
              <>
                <InputLabel>Allocate To</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setSelectedUser(e.target.value)}
                >
                  {users.map((user, key) => {
                    return (
                      <MenuItem value={user.uuid} key={key}>
                        {user.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : (
              <Loading />
            )}

            <TextField
              margin="dense"
              name="lead_contact_source"
              label="Lead Contact Source"
              multiline
              onChange={(e) => {
                setSource(e.target.value);
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              name="notes"
              label="Notes"
              multiline
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              fullWidth
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="secondary">
            Close
          </Button>
          <Button
            onClick={() => handleConvert()}
            color="primary"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
